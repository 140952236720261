let currentPlayer;
let modal;
let modalType;

const MODAL_CLASS = 'vjs-r7-block-msg';

const getDefaultTemplate = (data) => {
  const { href } = data.href ? data : window.location;
  const { buttonLabel } = data.buttonLabel ? data : { buttonLabel: 'Recarregar' };
  const button = `<a href="${href}" title="Recarregar" class="${MODAL_CLASS}__button" target="_self" rel="noreferrer noopener" data-layer-dispatch-cancel>${buttonLabel}</a>`;
  const ctaButton = data.button ? button : '';
  const outlineIconClass = data.outlineIcon ? `${MODAL_CLASS}__icon--outline` : '';
  const outlineContainedClass = data.outlineContained ? `${MODAL_CLASS}__icon--contained` : '';

  const template = `
    <div class="${MODAL_CLASS}__icon ${outlineIconClass} ${outlineContainedClass}">${data.icon}</div>
    <div class="${MODAL_CLASS}__title">${data.title}</div>
    <div class="${MODAL_CLASS}__desc">${data.desc}</div>
    ${ctaButton}`;

  const container = document.createElement('div');
  container.classList.add(`${MODAL_CLASS}__container`);
  container.innerHTML = template;

  return container;
};

const getBlockContent = () =>
  getDefaultTemplate({
    icon: 'lock',
    outlineContained: true,
    title: 'Conteúdo bloqueado',
    desc: 'O conteúdo desse vídeo não pode ser exibido nessa página. Assista-o em uma página R7.COM',
    button: true,
    buttonLabel: 'Assistir no R7',
    href: 'https://www.r7.com',
  });

const getGeoBlockContent = () =>
  getDefaultTemplate({
    icon: 'explore',
    outlineIcon: true,
    title: 'Conteúdo bloqueado',
    desc: 'Lamentamos, mas o vídeo que está tentando assisitr é de exibição exclusiva em território brasileiro :-(',
    button: false,
  });

const getErrorContent = () =>
  getDefaultTemplate({
    icon: 'error_outline',
    title: 'Oops! Algo deu errado',
    desc: 'Por favor, recarregue a página.',
    button: true,
  });

const getBlockAdsContent = () =>
  getDefaultTemplate({
    icon: 'block',
    outlineIcon: true,
    title: 'Anúncios bloqueados',
    desc: 'Para continuar assistindo esse vídeo, por favor desabilite seu bloqueador de anúncios e recarregue a página.',
    button: true,
  });

const getOldBrowserContent = () =>
  getDefaultTemplate({
    icon: 'browser_not_supported',
    title: 'Navegador desatualizado',
    desc: 'Seu navegador parece não nos entender! Por favor, atualize-o para reproduzir este conteúdo.',
    button: false,
  });

const TYPE_OF_MSG = {
  BLOCK: getBlockContent,
  GEO_BLOCK: getGeoBlockContent,
  ERROR: getErrorContent,
  NO_AD: getBlockAdsContent,
  OLD_BROWSER: getOldBrowserContent,
};

const getModalOptions = () => ({
  label: '',
  fillAlways: true,
  temporary: false,
});

const getSuggestedContent = (type) => (type ? type() : TYPE_OF_MSG.ERROR());

const showDialog = () => {
  const modalOptions = getModalOptions();
  const suggestedContent = getSuggestedContent(modalType);

  modal = currentPlayer.createModal(suggestedContent, modalOptions);
  modal.addClass(MODAL_CLASS);
  modal.closeable(false);
};

const showErrorMsg = (player, type) => {
  // A partir de 28/10/2020, não deve ser mais exibida ess tela de bloqueio
  // Somente deve ser registrado o evento no analytics (etapa anterior)
  if (type === TYPE_OF_MSG.OLD_BROWSER) {
    return undefined;
  }

  currentPlayer = player;
  modalType = type;
  return showDialog();
};

export { showErrorMsg, TYPE_OF_MSG };
