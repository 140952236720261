const DEFAULT_PARAMS = {
  p: true,
  autoPlay: false,
  logo: true,
  playlistId: false,
  calhau: false,
  share: true,
  sz: false,
  full: true,
  customAdHash: '',
  referrerUrl: '',
  externalLinks: true,
  debug: false,
  debugHash: false,
  debugRelated: false,
  timerRelated: true,
  logoRecord: false,
  contextModal: false,
  parentUrl: 'https://record.r7.com/',
  t: '0',
  noTitle: false,
};

export default DEFAULT_PARAMS;
