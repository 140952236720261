import { showErrorMsg, TYPE_OF_MSG } from '../helpers/messages';

let globalAdBlock;

const REQUEST_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
const REQUEST_CONFIG = {
  method: 'HEAD',
  mode: 'no-cors',
};

const checkAdsBlocked = () =>
  fetch(REQUEST_URL, REQUEST_CONFIG)
    .then(
      (response) => response.redirected // ads are blocked if request is redirected
    ) // (we assume the REQUEST_URL doesn't use redirections)
    .catch(
      () => true // ads are blocked if request fails
    ); // (we do not consider connction problems)

const setupAdBlock = async () => {
  if (globalAdBlock === undefined || globalAdBlock === '') {
    globalAdBlock = await checkAdsBlocked();
  }
  return globalAdBlock;
};

const hasAdBlock = () => globalAdBlock;

const showAdBlockBreaker = (player) => {
  player.on('ready', () => {
    player.trigger('tracking:adBlocked');
    showErrorMsg(player, TYPE_OF_MSG.NO_AD);
  });
};

export { hasAdBlock, showAdBlockBreaker, setupAdBlock };
