import { setupAdBlock } from './adblock';

const loadIMA = async (callback) => {
  const url = '//imasdk.googleapis.com/js/sdkloader/ima3.js';
  const hasIma = window.google && window.google.ima;
  const adBlock = await setupAdBlock();

  if (adBlock || hasIma) {
    callback();
    return false;
  }

  const script = document.createElement('script');
  script.src = url;
  script.onload = callback;
  script.onerror = callback;

  document.head.appendChild(script);
  return true;
};

export default loadIMA;
