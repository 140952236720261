import { showErrorMsg, TYPE_OF_MSG } from './helpers/messages';

let videojs;
let langPt;
let getParams;
let getMetadatas;
let configParams;
let initPlugins;
let eventTracking;
let googleAnalytics;
let initAds;
let vjsYoubora;
let initComscore;
let kibana;

const initR7Player = async () => {
  const options = {
    autoSetup: false,
    controls: true,
    muted: true,
    fill: true,
    language: 'pt-br',
    playbackRates: [0.5, 1, 1.5, 2],
    responsive: true,
    languages: langPt,
    withCredentials: true,
    liveui: true,
    playsinline: true,
    fullscreen: {
      navigationUI: 'show',
    },
    html5: {
      vhs: {
        overrideNative: !videojs.browser.IS_ANY_SAFARI,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: false,
      preloadTextTracks: true,
    },
    controlBar: {
      volumePanel: { inline: false },
      fullscreenToggle: true,
    },
    techOrder: ['chromecast', 'html5'],
  };

  const getPlayerElement = async (element) =>
    new Promise((resolve) => {
      const timer = setInterval(() => {
        if (element && element.length > 0) {
          clearInterval(timer);
          resolve(element);
        }
      }, 100);
    });

  const $r7players = await getPlayerElement(document.querySelectorAll('.video-js'));
  $r7players.forEach(($r7player) => {
    const r7player = videojs($r7player, options);
    r7player.addClass('vjs-r7-skin');
    try {
      const params = getParams(r7player);
      const metadatas = getMetadatas(r7player);

      initPlugins(r7player, params);
      configParams(r7player, params);
      googleAnalytics(r7player, metadatas);
      eventTracking(r7player);
      vjsYoubora(r7player, metadatas);
      initComscore(r7player);
      initAds(videojs, r7player, params, metadatas);
      kibana(r7player);
    } catch (unused) {
      window.console.log('[player]', 'error', unused);
      showErrorMsg(r7player, TYPE_OF_MSG.ERROR);
    }
  });
};

const startR7Player = () => {
  // import videojs from 'video.js';
  import('video.js').then((vjs) => {
    videojs = vjs.default;

    import('./start-player-extras').then((resp) => {
      langPt = resp.langPt;
      getParams = resp.getParams;
      getMetadatas = resp.getMetadatas;
      configParams = resp.configParams;
      initPlugins = resp.initPlugins;
      googleAnalytics = resp.googleAnalytics;
      eventTracking = resp.eventTracking;
      initAds = resp.initAds;
      vjsYoubora = resp.vjsYoubora;
      initComscore = resp.initComscore;
      kibana = resp.kibana;

      initR7Player().then(() => {
        window.r7player = true;
      });
      window.videojs = videojs;
    });
  });
};

export default startR7Player;
