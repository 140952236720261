import { hasAdBlock } from './ads/adblock';
import loadIMA from './ads/ima';
import { disableDefaultAutoplay } from './helpers/helpers';
import startR7Player from './start-player';

document.addEventListener('DOMContentLoaded', () => {
  disableDefaultAutoplay();
  loadIMA(() => {
    const timer = setInterval(() => {
      const hasIma = window.google && window.google.ima;

      if (hasAdBlock() || hasIma) {
        clearInterval(timer);
        startR7Player();
      }
    }, 100);
  });
});
