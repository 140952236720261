import DEFAULT_PARAMS from '../utils/default-params';
import BrowserDetector from './browser-detector';

let relatedVideos = [];

export const BOOLEAN_MAP = {
  truthy: [true, 'true', 1, '1', 'on', 'yes'],
  falsy: [false, 'false', 0, '0', 'off', 'no'],
};

export const toBoolean = (param) => BOOLEAN_MAP.truthy.includes(param);

export const isBoolean = (param) => [...BOOLEAN_MAP.truthy, ...BOOLEAN_MAP.falsy].includes(param);

export const isArcEnviroment = () => document.querySelector('[data-arc-video]') !== null;

export const completeWithDefaultParams = (playerParams) => {
  const defaultParams = DEFAULT_PARAMS;

  const newParams = {};

  Object.keys(defaultParams).forEach((param) => {
    let paramToUse = defaultParams[param];

    if (Object.prototype.hasOwnProperty.call(playerParams, param)) {
      paramToUse = playerParams[param];
    }

    newParams[param] = paramToUse;
  });

  return newParams;
};

export const updateParamsFromUrl = (urlParams, targetParams) => {
  const newParams = { ...targetParams };

  if (urlParams) {
    Object.keys(urlParams).forEach((param) => {
      const isValidParam = Object.prototype.hasOwnProperty.call(targetParams, param);
      if (isValidParam) {
        newParams[param] = isBoolean(urlParams[param]) ? toBoolean(urlParams[param]) : urlParams[param];
      }
    });
  }
  return newParams;
};

export const toNumber = (param) => parseInt(param, 10);

export const toString = (param) => (param === '' ? false : param);

export const formatTwoDigits = (value) => `0${value}`.slice(-2);

export const verifyUrl = (url) => {
  const defaultUrl = '#';
  return url.includes('r7.com') ? url : defaultUrl;
};

export const getBaseWebbUrl = (link) => {
  const regExpUrl = link.match(/((qa)[\d])/g);
  if (regExpUrl) {
    const qaNumber = parseInt(regExpUrl.toString().split('').reverse().join(''), 10);
    return `//cms-media-api-qa${qaNumber}.ir7.com.br`;
  }
  return '//cms-media-api.r7.com';
};

export const verifyLocal = (link) => {
  const url = getBaseWebbUrl(link);
  return `${url}/video/`;
};

export const isSuportedBrowser = () => {
  const browserDetector = new BrowserDetector();
  return browserDetector.isSupported();
};

export const isMobile = () => {
  const browserDetector = new BrowserDetector();
  return browserDetector.isMobile;
};

export const isChromeAndroid = () => {
  const browserDetector = new BrowserDetector();

  return browserDetector.isAndroid && browserDetector.isChrome;
};

export const isSafariIos = () => {
  const browserDetector = new BrowserDetector();

  return browserDetector.isIOS || browserDetector.isSafari;
};

export const formatDate = (date) => {
  const newDate = new Date(date);

  const getDay = formatTwoDigits(newDate.getDate());
  const getMonth = formatTwoDigits(newDate.getMonth() + 1);
  const getYear = newDate.getFullYear();
  const getFullDate = `${getDay}/${getMonth}/${getYear}`;
  const getHour = formatTwoDigits(newDate.getHours());
  const getMinutes = formatTwoDigits(newDate.getMinutes());
  const getFullTime = `${getHour}:${getMinutes}`;

  return {
    getDay,
    getMonth,
    getYear,
    getFullDate,
    getFullTime,
  };
};

export const isLiveVideo = (duration) => duration === Infinity;

export const fetchRelatedVideos = (player, videoId, callbackFunc, useMostViewedInstead) => {
  if (relatedVideos.length === 3) {
    callbackFunc(player, relatedVideos);
    return false;
  }

  const pathname = useMostViewedInstead ? '/videos/most_viewed' : `/videos/related/${videoId}`;
  const locationUrl = getBaseWebbUrl(window.location.href);
  const url = locationUrl + pathname;

  fetch(url)
    .then((data) => data.json())
    .then((data) => {
      relatedVideos = data.filter((obj, idx) => obj && idx < 3);
      callbackFunc(player, relatedVideos);
    })
    .catch(() => !useMostViewedInstead && fetchRelatedVideos(player, videoId, callbackFunc, true));

  return true;
};

export const isLocalhost = () => /(cma-dev|localhost)/.test(window.location.hostname);

export const isDevelopEnv = () => /(^\d{1,3}|ir7\.com|localhost)/.test(window.location.hostname);

export const isInIframe = () => window.location !== window.parent.location;

export const disableDefaultAutoplay = () => {
  const videos = document.querySelectorAll('.video-js');
  videos.forEach((video) => {
    const v = video;
    v.autoplay = false;
    v.controls = false;
  });
};

// em performance, melhora o perspectiva do usuário em relação
// ao carregamento da imagem
export const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};

export const searchToObject = () => {
  const pairs = window.location.search.substring(1).split('&');
  if (pairs[0] !== '') {
    const obj = {};

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const decodeKey = decodeURIComponent(key);
      obj[decodeKey] = decodeURIComponent(value);
    });

    return obj;
  }

  return false;
};

export const isEmbeded = () => window.self !== window.top;

export const getPlayerDurationSegments = (duration) => {
  const segments = {
    firstQuarter: 0,
    secondQuarter: 0,
    thirdQuarter: 0,
  };
  if (duration > 0) {
    // A duração vem em milisegundos para garantir que
    // ao dividir os quartos deem valores 'inteiros'
    // é necessario converter de milisegundos para segundos.
    const durationInSeconds = duration / 1000;

    const quarterInSeconds = (durationInSeconds / 4).toFixed(0);

    // O valor inteiro em segundos do quarto de duração
    // é convertido de volta para milisegundos para garantir
    // a consistencia com a duração vinda do player
    const quarterInMiliseconds = quarterInSeconds * 1000;

    segments.firstQuarter = +quarterInMiliseconds;
    segments.secondQuarter = +quarterInMiliseconds * 2;
    segments.thirdQuarter = +quarterInMiliseconds * 3;
  }

  return segments;
};

export const formatDurationFromMetadata = (duration) => {
  const durationInSeconds = parseInt(duration / 1000, 10);

  const hour = Math.floor(durationInSeconds / 3600);
  const minute = Math.floor((durationInSeconds % 3600) / 60);
  const second = Math.floor((durationInSeconds % 3600) % 60);

  const formatedDuration = {
    hour: formatTwoDigits(hour),
    minute: formatTwoDigits(minute),
    second: formatTwoDigits(second),
  };

  if (formatedDuration.hour > 0) {
    return `${formatedDuration.hour}:${formatedDuration.minute}:${formatedDuration.second}`;
  }

  return `${formatedDuration.minute}:${formatedDuration.second}`;
};

export const getAdPlacement = (player) => {
  const fullDuration = player.duration();
  const currTime = player.currentTime();

  const percentToUse = 5;
  const threshold = (fullDuration * percentToUse) / 100;
  const prerollThreshold = threshold;
  const postrollThreshold = fullDuration - threshold;

  switch (true) {
    case currTime <= prerollThreshold:
      return 'pre-roll';
    case currTime > prerollThreshold && currTime < postrollThreshold:
      return 'mid-roll';
    case currTime >= postrollThreshold:
      return 'post-roll';
    default:
      return undefined;
  }
};

export const showChromecastBtn = (player, show = true) => {
  const timer = setInterval(() => {
    const chromecastBtn = player.controlBar.getChild('chromecastButton');

    if (chromecastBtn) {
      if (show) {
        chromecastBtn.enable();
      } else {
        chromecastBtn.disable();
      }
      clearInterval(timer);
    }
  }, 500);
};

export const verifyEndCasting = (player) => {
  const duration = parseInt(player.duration().toFixed(0), 10);
  const currentTime = parseInt(player.currentTime().toFixed(0), 10);

  const floorTime = currentTime - 1;
  const ceilTime = currentTime + 1;

  const verifiedDuration = duration !== 0;
  const verifiedTime = currentTime === duration || floorTime === duration || ceilTime === duration;

  return !(verifiedDuration && verifiedTime);
};

export const getMediaInfo = (videoId, callback) => {
  try {
    const locationUrl = verifyLocal(window.location.href);
    const url = locationUrl + videoId;

    if (videoId) {
      fetch(url)
        .then((data) => data.json())
        .then((response) => callback(response));
    }
  } catch (e) {
    window.console.log(e);
  }
};

export const cleanUrl = (url) => {
  const protocolRegex = /((https?:)?\/\/)/g;
  const cleanedProtocol = url.replace(protocolRegex, '');
  const portRegex = /:\d+/g;

  return cleanedProtocol.replace(portRegex, '');
};

export const fixUrlProtocol = (url) => {
  const cleanedUrl = cleanUrl(url);

  return `https://${cleanedUrl}`;
};

export const removeSpecialChar = (sentence) => sentence.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const replaceSpaces = (sentence) => sentence.replace(/\s/gi, '-');

export const sanitizeSectionName = (sectionName) => {
  let sanitizedSectionName = removeSpecialChar(sectionName);
  sanitizedSectionName = replaceSpaces(sanitizedSectionName).toLowerCase();

  return sanitizedSectionName;
};

export const getEventByContext = () => (isMobile() ? 'touchend' : 'click');

export const isLegacyVideo = (player) => {
  const playerUrl = player.tagAttributes['data-player-url'] || player.tagAttributes.player_url;

  return playerUrl === 'legacy';
};

export const getRelatedVideos = (player) =>
  player.tagAttributes['data-related-videos'] ? JSON.parse(player.tagAttributes['data-related-videos']) : [];

export const getVideoId = (player) => {
  if (isArcEnviroment() && !isLegacyVideo(player)) {
    const playerUrl = player.tagAttributes['data-player-url'];
    const regex = /\/player\/([^/]+)/;
    const match = playerUrl.match(regex);
    const arcVideoId = match ? match[1] : '0';

    return arcVideoId;
  }
  const src = player.src();
  const url = new URL(src);
  const vId = url.pathname.split('/').find((p, i, a) => i > 0 && a[i - 1] === 'i');

  return vId;
};
